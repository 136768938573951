// @flow

export function fetchProduct(id: string): Object {
  return {
    type: 'FETCH_PRODUCT',
    payload: {
      id,
      fetchTime: new Date().toISOString()
    }
  }
}
