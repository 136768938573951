// @flow

import type {
  AddNotificationAction,
  RemoveNotificationAction,
  NotificationType
} from '../types';

export function addNotification(id: string, msg: ?string, type: ?NotificationType = 'error'): AddNotificationAction {
  return {
    type: 'ADD_NOTIFICATION',
    payload: {
      id,
      type,
      msg,
      addTime: new Date().toISOString()
    }
  }
}

export function removeNotification(id: string): RemoveNotificationAction {
  return {
    type: 'REMOVE_NOTIFICATION',
    payload: {
      id
    }
  }
}
