// @flow

import React from 'react';

export default function MenuClickComp(props: Object) {

  const {compFunc, children, ...other} = props;
  const Comp = compFunc;

  return (
    <Comp {...other} textColor='black' hoverTextColor='black600'>
      {children}
    </Comp>
  );
}
