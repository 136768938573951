// @flow

import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  RiShoppingBasketLine as CartIcon,
  RiMenuFill as MobileMenuIcon
} from 'react-icons/ri';

import {GrFacebook as FacebookIcon} from 'react-icons/gr';

import Collapse from 'atomize/dist/atoms/collapse/Collapse';

import Div from 'atomize/dist/atoms/div/Div';
import Container from 'atomize/dist/atoms/grid/Container';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Text from 'atomize/dist/atoms/text/Text';
import Anchor from 'atomize/dist/atoms/anchor/Anchor';

import MenuLink from './MenuLink';
import MenuButton from './MenuButton';

import {getTotalCartItemsQty} from '../redux/selectors/cartSelectors';


function MainMenuItem(props: Object) {
  const {children, ...other} = props;
  return (
    <Div p={{
      // r: {xs: '1rem', sm: '4rem'},
      b: {xs: '1rem', sm: '2rem'},
      t: {xs: '1rem', sm: '2rem'}
    }}
      {...other}>
      {children}
    </Div>
  );
}

function SecondaryLinks(props: Object) {

  return (
    <>
      <MainMenuItem m={{r: {xs: '1rem', sm: '2rem'}}}>
        <MenuLink to='/blogas'>blogas</MenuLink>
      </MainMenuItem>
    </>
  )
}

function ContactsMenu() {
  return (
    <Div d='flex' justify='flex-end' textSize='caption'>

      <Div>
        <Text textSize='caption'>+37060023315</Text>
      </Div>
      <Div p={{l: '1rem'}}>
        <MenuLink to='mailto:info@bukitesveiki.lt'>
          info@bukitesveiki.lt
        </MenuLink>
      </Div>
      <Div p={{l: '1rem'}}>
        <MenuLink aria-label='bukitesveiki.lt Facebook puslapis' forceLegacy target='_blank' to='https://www.facebook.com/bukitesveikilt-316389365211216'>
          <FacebookIcon size='1.25rem'/>
        </MenuLink>
      </Div>

    </Div>
  );
}

export default function Navigator() {

  const [isOpen, setOpen] = useState(false);
  const itemCount = useSelector(getTotalCartItemsQty);
  const dispatch = useDispatch();

  return (
    <Container>
      <Div p={{t: '1rem', b: '1rem'}}>

        <ContactsMenu />

        <Div d='flex' justify='space-between' p={{t: '1rem'}}>
          {
            // TODO: uncomment when there are secondary links, e.g. blog
            <MainMenuItem d={{xs: 'flex', sm: 'none'}}>
              <MenuButton aria-label='meniu' onClick={() => setOpen(!isOpen)}>
                <MobileMenuIcon size='1.75rem'/>
              </MenuButton>
            </MainMenuItem>
          }

          <MainMenuItem>
            <MenuLink textWeight='600' textSize='subheader' to='/'>bukitesveiki.lt</MenuLink>
          </MainMenuItem>

          <Div d='flex'>
            <Div d={{xs: 'none', sm: 'flex'}}>
              <SecondaryLinks />
            </Div>
            <MainMenuItem>
              {
                // NOTE: this isn't an internal page - gatsby doesn't recognize it!
              }
              <MenuButton aria-label='krepšelis' onClick={() => dispatch({type: 'OPEN_CART'})}> <CartIcon size='1.75rem'/>
                <Anchor tag='span' textColor='success700' hoverTextColor='success800'>{!!itemCount && itemCount}</Anchor>
              </MenuButton>
            </MainMenuItem>
          </Div>
        </Div>

        {
          // TODO: uncomment when there are secondary links, e.g. blog
          <Div d={{xs: 'flex', sm: 'none'}}>
            <Collapse isOpen={isOpen} >
              <SecondaryLinks />
            </Collapse>
          </Div>
        }


      </Div>
    </Container>
  );
}
