// @flow

import React from 'react';

import Link from './Link';
import MenuClickComp from './MenuClickComp';

export default function MenuLink(props: Object) {

  const {children, ...other} = props;

  return (
    <MenuClickComp compFunc={Link} {...other}>
      {children}
    </MenuClickComp>
  );
}
