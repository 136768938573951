// @flow

const initialState = {

}

export default function productsReducer(state: Object = initialState, action: Object) {
  switch(action.type) {
    case 'FETCH_PRODUCT': {
      const {id, fetchTime} = action.payload;
      const productState = state[id] || {};
      const newState = {
        ...state,
        [id]: {...productState, fetchTime, fetching: true}
      }

      return newState;
    }
    case 'FETCH_PRODUCT_SUCCESS': {
      const {id, product} = action.payload;
      const productState = state[id] || {};
      const newState = {
        ...state,
        [id]: {...productState, ...product, fetching: false}
      }

      return newState;
    }
    case 'CREATE_ORDER_INTENT_SUCCESS': {

      const {cartProducts} = action.payload;

      const updatedState = {};

      cartProducts.forEach(p => {
        updatedState[p.id] = {
          ...state[p.id],
          stock: p.stock,
          price: p.price
        };
      });

      return {
        ...state,
        ...updatedState
      };
    }
    // case 'STATIC_PRODUCT_INFO_LOADED': {
    //   const {id} = action.payload;
    //   const productState = state[id] || {};
    //   return {
    //     ...state,
    //     [id]: {...productState, ...action.payload}
    //   }
    // }

    // no default
  }

  return state;
}
