// @flow

import {call, put} from 'redux-saga/effects';
import axios from 'axios';

import {addNotification} from '../actions/notificationsActions';
import logger from '../../lib/logger';
import {validateCartProduct} from './cartSagas';

export function* fetchProduct(action: Object): Generator<*, void, *> {

  const {id} = action.payload;

  const URL = `${process.env.GATSBY_API_URL}/v1/products/${id}`;

  try {
    const res = yield call(axios.get, URL);
    const product = res.data;

    yield call(validateCartProduct, product);

    yield put({
      type: 'FETCH_PRODUCT_SUCCESS',
      payload: {
        id,
        product
      }
    });
  } catch (e) {
    logger.error(`Failed to fetch product ${id}, error ${JSON.stringify(e)}`);
    //yield put(addNotification('FETCH_PRODUCT'));
  }
}
