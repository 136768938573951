// @flow

import {call, put, select} from 'redux-saga/effects';

import axios from 'axios';

import {addNotification} from '../actions/notificationsActions';
import {validateCartProduct} from './cartSagas';

import type {
  CreateOrderIntentAction,
  CreateOrderIntentSuccessPayload
} from '../types';

export function* createOrderIntent(action: CreateOrderIntentAction): Generator<*, void, *> {

  const URL = `${process.env.GATSBY_API_URL}/v1/orders/intent`;

  try {
    const {payload} = action;
    const res = yield call(axios.post, URL, payload);
    const data: CreateOrderIntentSuccessPayload = res.data;

    for (let p of data.cartProducts) {
      yield call(validateCartProduct, p);
    }

    yield put({
      type: 'CREATE_ORDER_INTENT_SUCCESS',
      payload: data
    });

  } catch (e) {

    yield put(addNotification('CREATE_ORDER_INTENT'));
  }
}
