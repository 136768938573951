// @flow

import PageElemWrapper from './src/components/PageElemWrapper';
import React from 'react';
import RootElemWrapper from './src/components/RootElemWrapper';

export const wrapPageElement = ({ element, props }) => {
  return <PageElemWrapper {...props}>{element}</PageElemWrapper>
}

export const wrapRootElement = ({ element }) => {
  return <RootElemWrapper>{element}</RootElemWrapper>
}
