// @flow

const initialState = {
  isOpen: false,
  shippingFee: 3,
  itemsById: {}
}

export default function cartReducer(state: Object = initialState, action: Object) {

  const {type, payload} = action;

  switch(type) {
    case 'OPEN_CART': {
      return {
        ...state,
        isOpen: true
      }
    }
    case 'CLOSE_CART': {
      return {
        ...state,
        isOpen: false
      }
    }
    case 'PROCESS_PAYMENT_SUCCESS': {
      return initialState;
    }
    case 'REMOVE_CART_ITEM': {
      const {id} = payload;

      const {[id]: omit, ...newItemsById} = state.itemsById;

      return {
        ...state,
        itemsById: newItemsById
      }
    }
    // TODO: split into ADD_CART_ITM and UPDATE_CART_ITEM?
    case 'UPDATE_CART_ITEM': {
      const {id, addTime, newQty, relQtyChange} = payload;

      // either new quantity or relative quantity change should always be set

      const updatedItem = {
        addTime: addTime || state.itemsById[id].addTime,
        qty: newQty || ((state.itemsById[id] ? state.itemsById[id].qty : 0) + relQtyChange)
      };

      if (!updatedItem.qty) {
        const newItemsById = Object.keys(state.itemsById).reduce((object, key) => {
          if (key !== id) {
            object[key] = { ...state.itemsById[key] };
          }
          return object
        }, {});

        return {
          ...state,
          itemsById: newItemsById
        }
      }

      return {
        ...state,
        itemsById: {
          ...state.itemsById,
          [payload.id]: updatedItem
        }
      };
    }
    // case 'CREATE_ORDER_INTENT_SUCCESS': {
    //
    //   const {cartProducts} = payload;
    //   const newItemsById = {};
    //   cartProducts.forEach(p => {
    //     newItemsById[p.id] = {
    //       ...state.itemsById[p.id],
    //       qty: p.qty
    //     }
    //   });
    //
    //   return {
    //     ...state,
    //     itemsById: newItemsById
    //   }
    //
    // }

    // no default
  }

  return state;
}
