// @flow

import { createSelector } from 'reselect';
import {compare} from '../../lib/util';

import {getNotificationsState} from './index';

import type {Error, NotificationsState} from '../types';

const getOrderedNotifications = createSelector(getNotificationsState, (notificationsState: NotificationsState): Array<Error> => {

  const notifications: Array<Error> = [];
  for (const [key, value] of Object.entries(notificationsState)) {
    notifications.push(value);
  }

  return notifications.sort(compare('addTime'));

});

export {
  getOrderedNotifications
}
