// @flow

import {call, put, select} from 'redux-saga/effects';

import {getCartItem} from '../selectors/cartSelectors';
import {getProductPrice, getTotalProductStock} from '../selectors/productsSelectors';
import {updateCartItem} from '../actions/cartActions';
import {addNotification} from '../actions/notificationsActions';

import type {
  Product
} from '../types';

export function* validateCartProduct(product: Product): Generator<*, void, *> {

  const {id, stock} = product;

  const cartItem = yield select(getCartItem, id);
  if (cartItem) {

    if (cartItem.qty > stock) {
      yield put(addNotification('CART_ITEM_QTY_CHANGED', 'Kai kurių jūsų pasirinktų produktų nebeturime, todėl jie buvo automatiškai išimti iš krepšelio.', 'warn'));
      yield put(updateCartItem({
        id,
        newQty: stock
      }));
    } else {
      const price = yield select(getProductPrice, id);
      if (price && price !== product.price) {
        yield put(addNotification('CART_TOTAL_PRICE_CHANGED', 'Pakito pasirinktų produktų kaina.', 'info'));
      }
    }
  }
}
