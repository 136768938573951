// @flow

export function getCartState(state: Object): Object {
  return state.cart ? state.cart : state;
}

export function getProductsState(state: Object): Object {
  return state.products ? state.products : state;
}

export function getCheckoutState(state: Object): Object {
  return state.checkout ? state.checkout : state;
}

export function getNotificationsState(state: Object): Object {
  return state.notifications ? state.notifications : state
}

export function arePersisted(state: Object, keys: Array<string>): boolean {

  for (let key of keys) {
    if (!state[key]._persist || (state[key]._persist && !state[key]._persist.rehydrated))
    {
      return false;
    }
  }

  return true;
}
