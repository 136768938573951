// @flow

import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link as GatsbyLink } from "gatsby";

// import {
//   RiCloseLine as CloseIcon,
// } from 'react-icons/ri';
//import { IoIosArrowRoundBack as BackIcon } from 'react-icons/io';
//import {BsArrowLeft as BackIcon} from 'react-icons/bs';

import {BiChevronLeft as BackIcon} from 'react-icons/bi';

import SideDrawer from 'atomize/dist/atoms/sidedrawer/SideDrawer';
import Div from 'atomize/dist/atoms/div/Div';
import Text from 'atomize/dist/atoms/text/Text';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Button from 'atomize/dist/atoms/button/Button';
import Container from 'atomize/dist/atoms/grid/Container';

import MenuLink from '../MenuLink';
import CartItems from '../CartItems';

import {
  getOrderedCartProducts, isCartOpen, getTotalCartItemsQty, getCartItemsTotalPrice
} from '../../redux/selectors/cartSelectors';

export default function MiniCart(props: Object) {

  const dispatch = useDispatch();
  const isOpen = useSelector(isCartOpen);
  const products = useSelector(getOrderedCartProducts);
  const itemsCount = useSelector(getTotalCartItemsQty);
  const totalProductsPrice = useSelector(getCartItemsTotalPrice);

  const totalLbl = totalProductsPrice ? `${totalProductsPrice} €` : '';

  return (
    <SideDrawer w={{sm: '32rem', xs: '100vw'}} isOpen={isOpen} onClose={() => dispatch({type:'CLOSE_CART'})}>
      <Row>
        <Col size='2'>
          <MenuLink forceLegacy onClick={() => dispatch({type: 'CLOSE_CART'})}> <BackIcon size='1.75rem'/>
          </MenuLink>
        </Col>
        <Col size='8'>
          <Text tag='h3' textWeight='400' textSize='subheader' textAlign='center'>Krepšelis</Text>
        </Col>
      </Row>
      <Div p={{t: '1rem'}}>
        <Div border={{t: '1px solid'}} borderColor='gray300' />
        <Div h={{xs: 'calc(100vh - 18rem)', sm: 'calc(100vh - 16rem)'}} overflow='auto'>
          {
            !!itemsCount ?
            <CartItems id='miniCart' editable />
            : <Text p={{t: '4rem'}} textColor='gray900' textAlign='center'>Krepšelis tuščias</Text>
          }
        </Div>
      </Div>
      {
        // pos='fixed' bottom='1rem' right='5%'
        
        <Div>
          {
            !!itemsCount &&
            <>
              <Div p={{b: '1rem'}} border={{t: '1px solid'}} borderColor='gray300' />
              <Row p={{b: '2rem'}}>
                <Col>
                  <Text textSize='subheader' textWeight='400' textAlign='left'>Suma</Text>
                </Col>
                <Col>
                  <Text textSize='subheader' textWeight='400' textAlign='right'>{totalLbl}</Text>
                </Col>
              </Row>
              
              <GatsbyLink to='/checkout' onClick={() => dispatch({type: 'CLOSE_CART'})}>
                <Button rounded='circle' h='3rem' w='100%' shadow="3" hoverShadow="4" bg='success700' hoverBg='success800'>
                  Užsakyti
                </Button>
              </GatsbyLink> 
            </>
          }
        </Div>
      }

    </SideDrawer>
  );
}
