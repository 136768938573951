// @flow

import React from 'react';
import { Provider } from 'react-redux';

import {graphql, useStaticQuery} from 'gatsby';

import initStore from '../redux/initStore';

//import Spinner from './Spinner';

import {isPrerender} from '../lib/util';

// NOTE: Can't simply use it in product comps only. Issues when navigating to other pages (during node destroy)
let reduxInstance;


export default function RootElemWrapper(props: Object) {

  // TODO: think of ways to move this out of root component - with every page load, all products are loaded. This could become super slow with many products.
  const data = useStaticQuery(
    graphql`
      query ProductsQuery {
        allGraphCmsProduct {
          edges {
            node {
              excerpt
              slug
              title
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(maxWidth: 400, layout: FLUID)
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const products = {};
  data.allGraphCmsProduct.edges.forEach(({node}) => {

    const {slug, image, title, excerpt } = node;
    const imgData = image.localFile.childImageSharp.gatsbyImageData;

    products[slug] = {
      title,
      excerpt,
      imgData
    }

  });

  if (!reduxInstance) {
    const instance = initStore({
      products
    });
    reduxInstance = instance;
  }

  const {store, persistor} = reduxInstance;

  return (
    <Provider store={store}>

        {
          props.children
        }

    </Provider>
  );
}
