// @flow

import React from 'react';
import {useDispatch} from 'react-redux';

import Input from 'atomize/dist/atoms/input/Input';
import Button from 'atomize/dist/atoms/button/Button';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';

export default function PlusMinusBtn(props: Object) {

  const {count, maxCount, p, productId} = props;
  const minCount = 1;

  const dispatch = useDispatch();

  return (
    <Row p={p} m='0rem'>

      <Col p='0rem'>
        <Button h='2rem' w='100%' m='0rem' bg='white' textColor='black' borderColor='gray300' hoverBorderColor='gray300' border={{t: '1px solid', b: '1px solid', l: '1px solid'}} rounded='0' disabled={count === minCount} onClick={() => {
          if (count > minCount) {
            dispatch({
              type: 'UPDATE_CART_ITEM', payload: {id: productId, relQtyChange: -1}
            });
          }

        }}>-</Button>
      </Col>
      <Col p='0rem'>
        <Input h='2rem' type='number' textAlign='center' textSize='caption' w='100%' borderColor='gray300' hoverBorderColor='gray300' border={{t: '1px solid', b: '1px solid'}} rounded='0' value={count} onChange={evt => {
          const {value} = evt.target;
          if (value <= maxCount && value > 0) {
            dispatch({type: 'UPDATE_CART_ITEM', payload: {id: productId, newQty: Number(value)}});
          }
        }}/>
      </Col>
      <Col p='0rem'>
        <Button h='2rem' w='100%' bg='white' textColor='black' borderColor='gray300' hoverBorderColor='gray300' border={{t: '1px solid', b: '1px solid', r: '1px solid'}} rounded='0' disabled={count === maxCount} onClick={() => {
          if (count < maxCount) {
            dispatch({type: 'UPDATE_CART_ITEM', payload: {id: productId, relQtyChange: 1}});
          }
        }}>+</Button>
      </Col>

    </Row>
  )
}
