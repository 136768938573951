// @flow

import { takeEvery, takeLatest, all } from 'redux-saga/effects';

import {fetchProduct} from './productsSagas';
import {createOrderIntent} from './checkoutSagas';

export default function* rootSaga(args: Array<any>): Generator<*, void, *> {
  yield all([
    takeEvery('FETCH_PRODUCT', fetchProduct),
    takeLatest('CREATE_ORDER_INTENT', createOrderIntent)
  ]);
}
