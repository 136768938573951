// @flow

import { Link as GatsbyLink } from "gatsby";
import React from 'react';
import Anchor from 'atomize/dist/atoms/anchor/Anchor';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, state, activeClassName, partiallyActive, forceLegacy, ...other }) => {

  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash OR will be made of full URL, e.g. postseal.com/* (for services like contentful), and that anything else is external.
  const isInternal = !!to && (/^\/(?!\/)/.test(to) || to.indexOf(process.env.GATSBY_CLIENT_URL) >=0);

  // Use Gatsby Link for internal links, and <a> for others
  //if (!isInternal) {
  if (!isInternal || other.target === '_blank' || forceLegacy) {
  //if (true) {
    return (
      <a href={to} target={other.target} rel="noopener noreferrer">
        <Anchor tag='span' {...other}>
          {children}
        </Anchor>
      </a>
    );
  }

  return (
    <GatsbyLink to={to} state={state} activeClassName={activeClassName}
            partiallyActive={partiallyActive}>
      <Anchor tag='span' {...other}>
        {children}
      </Anchor>
    </GatsbyLink>
  )
}

export default Link
