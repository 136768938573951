// @flow

import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import { GatsbyImage } from "gatsby-plugin-image";

import Div from 'atomize/dist/atoms/div/Div';
import Text from 'atomize/dist/atoms/text/Text';
import Row from 'atomize/dist/atoms/grid/Row';
import Col from 'atomize/dist/atoms/grid/Col';
import Button from 'atomize/dist/atoms/button/Button';
import Container from 'atomize/dist/atoms/grid/Container';
import Anchor from 'atomize/dist/atoms/anchor/Anchor';

import MenuLink from './MenuLink';
import PlusMinusBtn from './PlusMinusBtn';

//import {TiDelete as DeleteIcon} from 'react-icons/ti';
import {RiDeleteBin2Line as DeleteIcon} from 'react-icons/ri';


import {
  getOrderedCartProducts, isCartOpen, getTotalCartItemsQty, getCartItemsTotalPrice
} from '../redux/selectors/cartSelectors';
import {fetchProduct} from '../redux/actions/productsActions';
import {removeCartItem} from '../redux/actions/cartActions';

export default function CartItems(props: Object) {

  const {textColor, editable} = props;

  const dispatch = useDispatch();

  const products = useSelector(getOrderedCartProducts);
  useEffect(() => {
    products.forEach(p => {
      if (!p.stock) {
        dispatch(fetchProduct(p.id));
      }
    })

  }, [])

  return products.map(product => {

    const {imgData, excerpt, qty, title, id, price, stock} = product;
    const priceLbl = price ? `${price} €` : ''

    // NOTE: don't remove margin 0 in Row. By default it's -8px, which creates
    // horizontal overflow
    return (
      <Row p={{t: '1rem'}} key={`${props.id}:Row:${id}`} m='0rem'>

        <Col size='2' p={{t: '1rem'}} >
          <GatsbyImage image={imgData} alt={`cart item ${title}`} />
        </Col>
        <Col size='1'></Col>
        <Col size='9'>

          <Row m='0rem' p={{t: '1rem'}} >
            <Col p='0rem'>
              <Text textWeight='600' textColor={textColor || 'black'}>
                {title}
              </Text>
            </Col>
            {
              editable &&
              <Col textAlign='right' p='0rem'>
                <MenuLink onClick={() => dispatch(removeCartItem(id))}>
                  <DeleteIcon size='1.25rem' />
                </MenuLink>
              </Col>
            }

          </Row>
          <Text textSize='tiny' p={{t: '1rem'}} textColor={textColor || 'black'}>
            {excerpt}
          </Text>

          <Row>
            <Col size={{xs: '4'}}>
              <Text textSize='caption' p={{t: '1rem'}} textColor={textColor || 'black'}>
                {priceLbl}
              </Text>
            </Col>
            <Col size={{xs: '8'}}>
              {
                editable
                ? <PlusMinusBtn p={{t: '1rem'}} count={qty} maxCount={stock} productId={id}/>
              : <Text textSize='caption' p={{t: '1rem'}} textColor={textColor || 'black'}>Kiekis: {qty}</Text>
              }
            </Col>

          </Row>
        </Col>

      </Row>
    );

  });
}
