// @flow

import type {UpdateCartItemPayload, UpdateCartItemAction} from '../types';

export function updateCartItem(payload: UpdateCartItemPayload): UpdateCartItemAction {
  return {
    payload,
    type: 'UPDATE_CART_ITEM'
  }
}

export function removeCartItem(id: string) {
  return {
    type: 'REMOVE_CART_ITEM',
    payload: {
      id
    }
  }
}
