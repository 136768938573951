// @flow

import { createSelector } from 'reselect';
import {compare} from '../../lib/util'

import round from 'lodash/round';

import {getCartState, getProductsState} from './index';

function getCartItemsById(state: Object): Object {
  return getCartState(state).itemsById;
}

function getCartItem(state: Object, id: string): ?Object {
  const itemsById = getCartItemsById(state);
  return itemsById[id];
}

function getCartItemQty(state: Object, id: string): number {
  const item = getCartItem(state, id);
  if (item) {
    return item.qty;
  }

  return 0;
}

function isCartOpen(state: Object): boolean {
  return getCartState(state).isOpen;
}

function getShippingFee(state: Object): number {
  return getCartState(state).shippingFee;
}

const getTotalCartItemsQty = createSelector(getCartItemsById, cartItems => {

  let totalQty = 0;
  for (const [key, value] of Object.entries(cartItems)) {
    totalQty += value.qty
  }

  return totalQty;
});

const getCartItemsTotalPrice = createSelector(getCartItemsById, getProductsState, (cartItems: Object, products: Object): number => {

  let totalPrice = 0;

  const cartProducts = [];
  for (const [key, value] of Object.entries(cartItems)) {
    totalPrice = round(totalPrice + (products[key].price * value.qty), 2);
  }

  return totalPrice;
});

const getTotalPrice = createSelector(getCartItemsTotalPrice, getShippingFee, (itemsPrice, shippingFee) => {

  return round(itemsPrice + shippingFee, 2);
})

const getOrderedCartProducts = createSelector(getCartItemsById, getProductsState, (cartItemsById: Object, products: Object): Array<Object> => {

  const cartProducts = [];
  for (const [key, value] of Object.entries(cartItemsById)) {
    cartProducts.push({
      id: key,
      ...value,
      ...products[key]
    });
  }

  return cartProducts.sort(compare('addTime'));

});

const getCartItems = createSelector(getCartItemsById, (cartItemsById: Object): Array<Object> => {

  const cartItems = [];
  for (const [key, value] of Object.entries(cartItemsById)) {
    cartItems.push({
      id: key,
      qty: value.qty
    });
  }

  return cartItems;

});

export {
  isCartOpen,
  getShippingFee,
  getCartItem,
  getCartItems,
  getCartItemsById,
  getCartItemQty,
  getTotalCartItemsQty,
  getOrderedCartProducts,
  getCartItemsTotalPrice,
  getTotalPrice
}
