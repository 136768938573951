// @flow

import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Anchor from 'atomize/dist/atoms/anchor/Anchor';

import {
  RiCloseLine as CloseIcon,
} from 'react-icons/ri';

import Notification from 'atomize/dist/atoms/notification/Notification';

import {getOrderedNotifications} from '../redux/selectors/notificationsSelectors';
import {removeNotification} from '../redux/actions/notificationsActions';

function getColours(type: 'error' | 'warn' | 'info' | 'success') {
  switch (type) {
    case 'info': {
      return {
        bg: 'info100',
        textColor: 'info800',
        hoverTextColor: 'info800'
      }
    }
    case 'success': {
      return {
        bg: 'success100',
        textColor: 'success800',
        hoverTextColor: 'success800'
      }
    }
    case 'warn': {
      return {
        bg: 'warning100',
        textColor: 'warning800',
        hoverTextColor: 'warning800'
      }
    }
    default: {
      return {
        bg: 'danger100',
        textColor: 'danger800',
        hoverTextColor: 'danger800'
      }
    }
  }
}

export default function Notifications(props: Object) {

  const dispatch = useDispatch();
  const notifications = useSelector(getOrderedNotifications);

  return notifications.map(e => {

    const colours = getColours(e.type);

    return (
      <Notification key={e.id} isOpen textSize='caption' {...colours} suffix={<Anchor tag='span' {...colours}><CloseIcon size='1.75rem' onClick={() => dispatch(removeNotification(e.id))} /></Anchor>}>
        {e.msg}
      </Notification>
    );
  });

}
