// @flow

export const isPrerender = typeof window === `undefined`;

export function compare(property: string, order: 'asc' | 'desc' = 'desc'): Function {
  const sortOrder = order === 'desc' ? -1 : 1;

  return function (a,b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}
