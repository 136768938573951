// @flow

import type {Action} from '../types';

const initialState = {

}

export default function notificationsReducer(state: Object = initialState, action: Action) {

  switch(action.type) {
    case 'ADD_NOTIFICATION': {
      const {payload} = action;

      return {
        ...state,
        [payload.id]: {
          id: payload.id,
          type: payload.type || 'error',
          msg: payload.msg || 'Įvyko klaida. Perkraukite puslapį ir bandykite dar kartą.',
          addTime: payload.addTime
        }
      }
    }
    case 'REMOVE_NOTIFICATION': {
      const {payload} = action;

      const { [payload.id]: value, ...newState } = state;
      return newState;
    }
  }

  return state;
}
