// @flow

const initialState = {

}

export default function checkoutReducer(state: Object = initialState, action: Object) {

  const {type, payload} = action;

  switch(type) {
    case 'UPDATE_CHECKOUT_INFO': {
      return {
        ...state,
        ...payload
      }
    }
    case 'CREATE_ORDER_INTENT_SUCCESS': {
      return {
        ...state,
        stripeClientSecret: payload.clientSecret
      }
    }
    case 'PROCESS_PAYMENT_SUCCESS': {
      return initialState;
    }
  }

  return state;
}
