// @flow

import {getProductsState} from './index';
import {DEFAULT_CURRENCY_SYMBOL} from '../../constants';

export function getProduct(state: Object, id: string): ?Object {
  const products = getProductsState(state);
  return products[id];
}

export function getProductPrice(state: Object, id: string): ?number {
  const product = getProduct(state, id);
  if (product) {
    return product.price;
  }
}

export function getProductPriceLbl(state: Object, id: string): string {
  const price = getProductPrice(state, id);
  if (price) {
    return `${price} ${DEFAULT_CURRENCY_SYMBOL}`
  }

  return '';
}

export function getTotalProductStock(state: Object, id: string): number {
  const product = getProduct(state, id);
  if (product) {
    return product.stock;
  }

  return 0;
}

export function getProductFetchTime(state: Object, id: string): ?string {
  const product = getProduct(state, id);

  if (product) {
    return product.fetchTime;
  }

  return null;
}

export function shouldFetch(state: Object, id: string): boolean {

  const lastFetchTime = getProductFetchTime(state, id);
  const now = new Date();

  const recentlyCached = lastFetchTime 
    && lastFetchTime > minusMinutes(now, 30).toISOString();

  return !recentlyCached;
}

function minusMinutes(date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
}

export function isFetching(state: Object, id: string): boolean {
  const product = getProduct(state, id);

  if (product) {
    return product.fetching;
  }

  return false;
}
