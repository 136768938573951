// @flow

import React from 'react';

import Button from 'atomize/dist/atoms/button/Button';
import MenuClickComp from './MenuClickComp';

export default function MenuButton(props: Object) {

  const {children, ...other} = props;

  // NOTE: if using icon as a child, h (aka height) has to match icon's size
  return (
    <MenuClickComp compFunc={Button} bg='white' p='0' h='1.75rem' {...other}>
      {children}
    </MenuClickComp>
  );
}
