// @flow

import LayoutWrapper from './LayoutWrapper';
import React from 'react';
import Navigator from './Navigator';

const PageElemWrapper = (props: Object) => {

  const {children, ...otherProps} = props;

  return (
    <LayoutWrapper {...otherProps}>
      <Navigator />
      {children}
    </LayoutWrapper>
  );
}

export default PageElemWrapper;
