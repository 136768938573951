// @flow

import React from 'react';

import Container from 'atomize/dist/atoms/grid/Container';
//import { StyleReset } from 'atomize';
import StyleReset from 'atomize/dist/atoms/StyleReset';
import {ThemeProvider} from 'atomize/dist/core/ThemeContext';

import MiniCart from './shop/MiniCart';
import Notifications from './Notifications';

import '../index.css';

const theme = {
  textSize: {
    size: {
      tiny: '12px',
      caption: '14px',
      body: '17px',
      paragraph: '17px',
      subheader: '22px',
      title: '26px',
      heading: '32px',
      display1: '40px',
      display2: '48px',
      display3: '56px'
    },
    height: {
      tiny: '20px',
      caption: '24px',
      body: '27px',
      paragraph: '30px',
      subheader: '32px',
      title: '40px',
      heading: '48px',
      display1: '48px',
      display2: '56px',
      display3: '56px'
    }
  },
  colors: {
    black900: '#0d0d0d',
    black800: '#1a1a1a',
    black700: '#262626',
    black600: '#333333',
    black500: '#404040',
    black400: '#4d4d4d',
    black300: '#595959',
    black200: '#666666',
    black100: '#737373',
  }
};

export default function LayoutWrapper(props: Object) {
  return (
    <ThemeProvider theme={theme}>
      <StyleReset />
      <MiniCart />
      <Notifications />
      {props.children}
    </ThemeProvider>
  );
}
