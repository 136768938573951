// @flow

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage' // defaults to localStorage for web

import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import cartReducer from './reducers/cartReducer';
import productsReducer from './reducers/productsReducer';
import checkoutReducer from './reducers/checkoutReducer';
import notificationsReducer from './reducers/notificationsReducer';

import logger from '../lib/logger';
import rootSaga from './sagas';

const defaultLoggerOptions = process.env.NODE_ENV === 'production' ? {
  logger,
  collapsed: true,
  diff: false,
  colors: {},
  titleFormatter: (action, time, took) =>  `${String(action.type)} @ ${time}`,
  // comment out if want to view prev/next state in dev
  stateTransformer: () => '',
} : {
  logger,
  collapsed: true
};

export default function initStore(preloadedState: Object = {}, loggerOptions: Object = defaultLoggerOptions) {

  logger.info('initialising redux store');

  const loggerMiddleware = createLogger(loggerOptions);
  const sagaMiddleware = createSagaMiddleware();

  const rootReducer = combineReducers({
    notifications: notificationsReducer,
    products: productsReducer,
    cart: persistReducer({
      storage: localforage,
      key: 'cart',
      blacklist: ['isOpen']
    }, cartReducer),
    checkout: persistReducer({
      storage: localforage,
      key: 'checkout',
      blacklist: ['stripeClientSecret']
    }, checkoutReducer)
  });

  const store = createStore(rootReducer, preloadedState, applyMiddleware(loggerMiddleware, sagaMiddleware));

  sagaMiddleware.run(rootSaga);

  const persistor = persistStore(store);

  return {
    store,
    persistor
  };
}
